import request from '@/utils/request.js'

// 文章列表
export function articleListApi(data) {
  return request({
    url: '/doftec/article/list',
    method: 'post',
    data: data
  })
}

// 文章新增
export function articleAddApi(data) {
  return request({
    url: '/doftec/article/create',
    method: 'post',
    data: data
  })
}

// 文章更新
export function articleUpdateApi(data) {
  return request({
    url: '/doftec/article/update',
    method: 'post',
    data: data
  })
}

// 文章详情
export function articleDetailApi(params) {
  return request({
    url: '/doftec/article/info',
    method: 'get',
    params: params
  })
}

// 文章删除/修改状态 操作状态：delete 删除 push 推送 download 下载
export function articleDeleteApi(data) {
  return request({
    url: '/doftec/article/action',
    method: 'post',
    data: data
  })
}