<template>
    <div class="app-content app-container">
        <!-- 搜索 -->
        <div style="margin-bottom: 20px">
            <el-form
                :inline="true"
                :model="formInline"
                class="demo-form-inline"
                ref="formInline"
                @submit.native.prevent
            >
                <el-form-item label="文章名称" prop="articleName">
                    <el-input
                        style="width: 300px"
                        v-model="formInline.articleName"
                        placeholder="请输入"
                        clearable
                        @keyup.enter.native="onSubmit"
                    ></el-input>
                </el-form-item>
                <el-form-item label="文章类型" prop="articleType">
                    <el-select
                        v-model="formInline.articleType"
                        placeholder="请选择"
                        clearable
                        @keyup.enter.native="onSubmit"
                    >
                        <el-option
                            v-for="item in articleTypeList"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item>
                    <el-button type="primary" @click="onSubmit">搜索</el-button>
                    <el-button @click="resetForm('formInline')">重置</el-button>
                </el-form-item>
            </el-form>
            <div>
                <el-button type="primary" plain @click="addReport"
                    >新增</el-button
                >
                <el-button type="danger" plain @click="allDeleteClick"
                    >删除</el-button
                >
            </div>
        </div>
        <!-- 表格 -->
        <div>
            <el-table
                ref="multipleTable"
                :data="tableData"
                tooltip-effect="dark"
                style="width: 100%"
                @selection-change="handleSelectionChange"
            >
                <el-table-column type="selection" width="55"></el-table-column>
                <el-table-column
                    prop="id"
                    label="#"
                    width="55"
                ></el-table-column>
                <el-table-column prop="articleName" label="文章名称">
                </el-table-column>
                <el-table-column prop="articleType" label="文章类型">
                    <template slot-scope="scope">
                        <span>{{ filterFun(scope.row.articleType) }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="createTime"
                    label="时间"
                ></el-table-column>

                <el-table-column label="操作" fixed="right" width="170">
                    <template slot-scope="scope">
                        <el-button type="text" @click="editClick(scope.row)"
                            >编辑</el-button
                        >
                        <el-button type="text" @click="previewClick(scope.row)"
                            >预览</el-button
                        >
                        <el-button type="text" @click="deleteClick(scope.row)"
                            >删除</el-button
                        >
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <div>
                <Pagination
                    v-bind:child-msg="pageparm"
                    @callFather="callFather"
                ></Pagination>
            </div>
        </div>
        <el-dialog title="" :visible.sync="dialogVisible" width="80%">
            <div v-if="dialogVisible && previewObj">
                <h1 style="text-align: center">{{ previewObj.articleName }}</h1>
                <el-divider></el-divider>
                <div>
                    <el-row type="flex">
                        <el-col style="text-align: center"
                            >发布日期：{{ previewObj.createTime }}</el-col
                        >
                        <el-col style="text-align: center"
                            >信息来源：{{
                                previewObj.informationSource
                            }}</el-col
                        >
                        <el-col style="text-align: center"
                            >浏览次数：{{ previewObj.browsingTimes }}</el-col
                        >
                    </el-row>
                </div>
                <div v-html="previewObj.articleContent"></div>
            </div>
            <!-- <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="editSubmitClick"
                    >确 定</el-button
                >
            </span> -->
        </el-dialog>
    </div>
</template>
<script>
import Pagination from '@/components/Pagination'
import { articleListApi, articleDeleteApi } from '@/api/article'
export default {
    // 注册组件
    components: {
        Pagination,
    },
    data() {
        return {
            previewObj: {},
            dialogVisible: false,
            articleTypeList: [
                {
                    label: '政策解读',
                    value: '2',
                },
                {
                    label: '金融动态',
                    value: '3',
                },
                {
                    label: '展会资讯',
                    value: '4',
                },
            ],
            formInline: {},
            tableData: [],
            multipleSelection: [],
            pageparm: {
                currentPage: 1,
                pageSize: 10,
                total: 20,
            },
        }
    },
    created() {
        if (sessionStorage.getItem('formInline')) {
            this.formInline = JSON.parse(sessionStorage.getItem('formInline'))
        }
        this.articleListFun()
    },
    methods: {
        filterFun(value) {
            var resR = this.articleTypeList.filter(
                (item) => item.value === value
            )
            return resR.length > 0 ? resR[0].label : ''
        },
        async articleListFun(currentPage, pageSize) {
            var start = currentPage ? currentPage * pageSize - pageSize : 0
            var params = {
                start: start,
                length: 10,
                draw: 1,
                orderStr:'id desc'
            }
            var p = Object.assign(params, this.formInline)
            var res = await articleListApi(p)
            this.tableData = res.data
            this.pageparm.total = res.recordsTotal
        },
        onSubmit() {
            sessionStorage.setItem(
                'formInline',
                JSON.stringify(this.formInline)
            )
            // console.log('onSubmit', this.formInline);
            this.articleListFun()
        },
        resetForm(formInline) {
            sessionStorage.removeItem('formInline')
            this.formInline = {
                name: '',
            }
            this.$refs[formInline].resetFields()
            this.articleListFun()
        },
        handleSelectionChange(val) {
            this.multipleSelection = val
        },
        // 分页插件事件
        callFather(parm) {
            this.articleListFun(parm.currentPage, parm.pageSize)
        },
        // 新增模板
        addReport() {
            this.$router.push({
                path: '/article/add',
            })
        },
        // 编辑模板
        editClick(row) {
            console.log('row', row)
            this.$router.push({
                path: '/article/add',
                query: {
                    id: row.id,
                },
            })
        },
        // 预览
        previewClick(row) {
            this.previewObj = row
            this.dialogVisible = true
            // 弹窗
            // this.$router.push({
            //   path:'/company/preview'
            // })
        },
        // 删除
        deleteClick(row) {
            console.log('row', row)
            var ids = [row.id]
            this.$alert(
                '这确定要删除' + row.articleName + '吗？删除后将无法恢复',
                '删除提示',
                {
                    confirmButtonText: '确定',
                    callback: async (action) => {
                        if (action === 'confirm') {
                            var params = {
                                ids: ids,
                                menuType: 'delete',
                            }
                            var res = await articleDeleteApi(params)
                            if (res.code === 200) {
                                this.$message({
                                    type: 'success',
                                    message: '删除成功',
                                })
                                this.articleListFun()
                            } else {
                                this.$message({
                                    type: 'error',
                                    message: '删除失败：' + res.message,
                                })
                            }
                        }
                    },
                }
            )
        },
        // 批量删除
        allDeleteClick() {
            var ids = this.multipleSelection.map((item) => item.id)
            this.$alert(
                '这确定要删除共' +
                    this.multipleSelection.length +
                    '条数据吗？删除后将无法恢复',
                '删除提示',
                {
                    confirmButtonText: '确定',
                    callback: async (action) => {
                        if (action === 'confirm') {
                            var params = {
                                ids: ids,
                                menuType: 'delete',
                            }
                            var res = await articleDeleteApi(params)

                            if (res.code === 200) {
                                this.$message({
                                    type: 'success',
                                    message: '删除成功',
                                })
                                this.articleListFun()
                            } else {
                                this.$message({
                                    type: 'error',
                                    message: '删除失败：' + res.message,
                                })
                            }
                        }
                    },
                }
            )
        },
    },
}
</script>
<style scoped>
.app-content {
    background-color: #fff;
    padding: 20px;
}
</style>
